import { Alert, Box, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router'

export const ContractExpiredNotification = () => {
  return (
    <Box mt={3}>
      <Alert variant="outlined" severity="warning">
        契約が終了しているため、現在メニューは非公開になっています。
        継続して利用される場合は、
        <Link to="/app/account" component={RouterLink}>
          アカウント画面
        </Link>
        からサブスクリプション契約をお願いします。 詳細については
        <Link
          href={'https://www.notion.so/184a922f625e8056904cfc64b5154a7a?pvs=4'}
        >
          ヘルプページ
        </Link>
        をご参照ください。
      </Alert>
    </Box>
  )
}
