import { mdxComponents } from '@/features/announcement/mdxComponents'
import Page from '@/features/layout/Page'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Box, Card, CardContent, Container, Typography } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import ReleasePastNoteJa from './releaseNotePastJa.mdx'

const AnnouncementPast: FC = () => {
  const { t } = useTranslation()

  return (
    <Page sx={styles.root} title={t('ui_dashboard:Past news')}>
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          古いお知らせ
        </Typography>
        <Box mt={3} />
        <Card css={styles.gridItem}>
          <CardContent>
            <Box m={2}>
              <ReleasePastNoteJa components={mdxComponents} />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  gridItem: {
    height: '100%',
    maxWidth: 640,
    overflow: 'hidden',
  },
} satisfies SxPropStyles

export default AnnouncementPast
