import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import invariant from 'tiny-invariant'
import { useMutationDeleteMenuitem } from '../api/useMutationDeleteMenuitem'

export function useDeleteMenuitem() {
  const { confirm } = useConfirmation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { isPending: isDeleting, mutate: mutateDelete } =
    useMutationDeleteMenuitem()

  const deleteMenuitem = async (menuitemId: string, menuitemName: string) => {
    invariant(menuitemId, '削除対象のメニューアイテムのIDがありません')

    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: t('ui_products:deletion confirmation', {
        name: menuitemName,
      }),
      title: t('Deletion'),
      variant: 'danger',
    })
    if (!yes) {
      return
    }

    mutateDelete(menuitemId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: queryKeys.menuitemsAll() })
        enqueueSnackbar(t('Deleted'), { variant: 'success' })
        navigate('/app/management/menuitems/', { replace: true })
      },
    })
  }

  return {
    deleteMenuitem,
    isDeleting,
  }
}
