import Page from '@/features/layout/Page'
import { MenuitemListViewCautionMailVerification } from '@/features/menuitemList/MenuitemListViewCautionMailVerification'
import { MenuitemListViewHeader } from '@/features/menuitemList/MenuitemListViewHeader'
import { MenuitemListViewResults } from '@/features/menuitemList/MenuitemListViewResults'
import { Container } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

const styles = {
  container: {
    paddingTop: 3,
    paddingBottom: 3,
  },
}

const MenuitemListView: FC = () => {
  const { t } = useTranslation()

  return (
    <Page sx={styles.container} title={t('Products')}>
      <Container maxWidth={false}>
        <MenuitemListViewHeader />
        <MenuitemListViewCautionMailVerification />
        <MenuitemListViewResults />
      </Container>
    </Page>
  )
}

export default MenuitemListView
