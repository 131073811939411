import type { GenreLite } from '@/features/api/types'
import { useMutationOrderGenre } from '@/features/genreList/api/useMutationOrderGenre'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload.ts'
import {
  type DragEndEvent,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import invariant from 'tiny-invariant'

/**
 * ドラッグアンドドロップによるジャンル並べ替え機能を提供するカスタムフック
 * 内部状態としてgenresを保持し、TanStack Queryのデータが更新された場合は同期する
 */
export const useDraggableGenres = (initialGenres: GenreLite[]) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  // ドラッグ操作のセンサー設定
  // タッチデバイスとマウスデバイスの両方に対応する
  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor))

  // 楽天更新のために、内部状態としてgenresを保持する。
  // TanStack Queryを使うと複雑になるので、useStateを使用した。
  const [genres, setGenres] = useState<GenreLite[]>(initialGenres)

  // TanStack Queryのデータと常に同期する。
  // 並べ替えのリクエスト成功のちのリフェッチ時を想定したもの。
  useEffect(() => {
    setGenres(initialGenres)
  }, [initialGenres])

  // ジャンルの並び順を更新するミューテーション
  const { isPending: isReordering, mutate: mutateReorderGenres } =
    useMutationOrderGenre()

  // DndContextのドラッグ終了イベントハンドラー
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    // ドロップ先が無い場合は何もしない
    if (!over) {
      return
    }

    // ドロップ先が同じ場合は何もしない
    if (active.id === over.id) {
      return
    }

    const oldIndex = genres.findIndex((item) => item.id === active.id)
    const newIndex = genres.findIndex((item) => item.id === over.id)

    // ソート済みのジャンル一覧
    const newGenres = arrayMove(genres, oldIndex, newIndex)

    // 移動したジャンル
    const movedItemId = newGenres[newIndex]?.id
    invariant(movedItemId)

    // 楽天更新
    setGenres(newGenres)

    // サーバーに並び順の更新を送信
    mutateReorderGenres(
      {
        movedItemId,
        newPosition: newIndex,
      },
      {
        onSuccess: async () => {
          // サーバー更新成功後、最新データを取得
          await queryClient.invalidateQueries({
            queryKey: queryKeys.genresAll(),
          })
          enqueueSnackbar('ジャンルの並び順を更新しました', {
            variant: 'success',
          })
        },
        onError: (e: unknown) => {
          // エラー時は元の状態に戻す
          setGenres(initialGenres)

          const customError = unwrapCustomErrorPayload(e)
          if (!customError) {
            enqueueSnackbar(t('Error occurred'), {
              variant: 'error',
            })
            return
          }
          return
        },
      },
    )
  }

  return {
    genres,
    isReordering,
    sensors,
    onDragEnd,
    collisionDetection: closestCenter,
  }
}
