import type { GenreLite } from '@/features/api/types'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

/**
 * すべてのジャンルを取得する。ドロップダウンなどでマスタデータ的に使われる想定。
 */
export function useQueryAllGenres() {
  return useSuspenseQuery({
    queryKey: queryKeys.genresAll(),
    queryFn: async (): Promise<GenreLite[]> => {
      const genres = await trpcClient.genre.getList.query()
      return genres.map((genre) => ({
        description: genre.description,
        id: genre.id,
        is_hidden: genre.isHidden,
        name: genre.name,
        order_num: genre.orderNum,
        product_count: genre.productCount,
      }))
    },
  })
}
