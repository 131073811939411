import type { Menuitem } from '@/features/api/types'
import { useMutationOrderProduct } from '@/features/menuitemList/api/useMutationOrderProduct'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload.ts'
import {
  type DragEndEvent,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import invariant from 'tiny-invariant'

/**
 * ドラッグアンドドロップによる商品並べ替え機能を提供するカスタムフック
 * 内部状態としてmenuitemsを保持し、TanStack Queryのデータが更新された場合は同期する
 */
export const useDraggableMenuitems = (initialMenuitems: Menuitem[]) => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  // ドラッグ操作のセンサー設定
  // タッチデバイスとマウスデバイスの両方に対応する
  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor))

  // 楽天更新のために、内部状態としてmenuitemsを保持する。
  // TanStack Queryを使うと複雑になるので、useStateを使用した。
  const [menuitems, setMenuitems] = useState<Menuitem[]>(initialMenuitems)

  // TanStack Queryのデータと常に同期する。
  // 並べ替えのリクエスト成功のちのリフェッチ時を想定したもの。
  useEffect(() => {
    setMenuitems(initialMenuitems)
  }, [initialMenuitems])

  // 商品の並び順を更新するミューテーション
  const { isPending: isReordering, mutate: mutateReorderProducts } =
    useMutationOrderProduct()

  // DndContextのドラッグ終了イベントハンドラー
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    // ドロップ先が無い場合は何もしない
    if (!over) {
      return
    }

    // ドロップ先が同じ場合は何もしない
    if (active.id === over.id) {
      return
    }

    const oldIndex = menuitems.findIndex((item) => item.id === active.id)
    const newIndex = menuitems.findIndex((item) => item.id === over.id)

    // ソート済みの商品一覧
    const newMenuitems = arrayMove(menuitems, oldIndex, newIndex)

    // 移動した商品
    const movedItemId = newMenuitems[newIndex]?.id
    invariant(movedItemId)

    // 移動した商品の一つ前の商品
    const previousItemId =
      newIndex - 1 > 0 ? (newMenuitems[newIndex - 1]?.id ?? null) : null

    // 移動した商品の一つ後の商品
    const nextItemId =
      newIndex + 1 < menuitems.length - 1
        ? (newMenuitems[newIndex + 1]?.id ?? null)
        : null

    // 楽天更新
    setMenuitems(newMenuitems)

    // サーバーに並び順の更新を送信
    mutateReorderProducts(
      {
        movedItemId,
        previousItemId,
        nextItemId,
      },
      {
        onSuccess: async () => {
          // サーバー更新成功後、最新データを取得
          await queryClient.invalidateQueries({
            queryKey: queryKeys.menuitemsAll(),
          })
          enqueueSnackbar('商品の並び順を更新しました', {
            variant: 'success',
          })
        },
        onError: (e: unknown) => {
          // エラー時は元の状態に戻す
          setMenuitems(initialMenuitems)

          const customError = unwrapCustomErrorPayload(e)
          if (!customError) {
            enqueueSnackbar(t('Error occurred'), {
              variant: 'error',
            })
            return
          }
          if (customError.type === 'orderProduct.genreMismatch') {
            return enqueueSnackbar('並べ替えは同一ジャンル内でのみ可能です', {
              variant: 'error',
            })
          }
          return
        },
      },
    )
  }

  return {
    menuitems,
    isReordering,
    sensors,
    onDragEnd,
    collisionDetection: closestCenter,
  }
}
