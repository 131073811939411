import { signOut } from '@/features/auth/firebaseAuthUtils'
import { useUserInfo } from '@/features/auth/state/authStatus'
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { type FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router'

const styles = {
  avatar: {
    height: 32,
    width: 32,
    marginRight: 1,
  },
  popover: {
    width: 200,
  },
}

const Account: FC = () => {
  const ref = useRef<HTMLElement>(null)

  const userInfo = useUserInfo()

  const { enqueueSnackbar } = useSnackbar()
  const [isOpen, setOpen] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose()
      await signOut()
      queryClient.clear()
    } catch (err) {
      console.error(err)
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" sx={styles.avatar} src={userInfo.avatar} />
        <Typography
          variant="h6"
          color="inherit"
          sx={{ display: { xs: 'none', md: 'block' } }} // 画面幅が狭い時は隠す
        >
          {userInfo.email}
        </Typography>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted={true}
        slotProps={{ paper: { sx: styles.popover } }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          onClick={handleClose}
          to="/app/account"
        >
          {t('Account')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('Sign out')}</MenuItem>
      </Menu>
    </>
  )
}

export default Account
