import { ClampText } from '@/components/ClampText'
import { useQueryAllGenres } from '@/features/api/commonQueries/useQueryAllGenres'
import type { Menuitem } from '@/features/api/types'
import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { CurrencyText } from '@/features/currency/_do_not_edit_CurrencyText'
import type { CurrencyCode } from '@/features/currency/_do_not_edit_currencySettings'
import { useQueryMenu } from '@/features/menuSetting/api/useQueryMenu'
import { useMutationDeleteMenuitems } from '@/features/menuitemList/api/useMutationDeleteMenuitems'
import { useQueryMenuitems } from '@/features/menuitemList/api/useQueryMenuitems'
import { useDraggableMenuitems } from '@/features/menuitemList/orderProduct/useDraggableMenuitems'
import { useUpdateProductSoldOut } from '@/features/menuitemList/updateProductSoldOut/useUpdateProductSoldOut.ts'
import { useUpdateProductVisibility } from '@/features/menuitemList/updateProductVisibility/useUpdateProductVisibility.ts'
import { useMenuitemsQueryCondition } from '@/features/menuitemList/useMenuitemsQueryCondition'
import { getImgixSrc } from '@/libraries/imgix/getImgixSrc'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useDebounce } from '@/utils/useDebounce'
import { DndContext } from '@dnd-kit/core'
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { css } from '@emotion/react'
import { Close as CloseIcon, DragIndicator } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  SvgIcon,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { type ChangeEvent, startTransition, useEffect, useState } from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router'

/**
 * 商品一覧表示コンポーネント
 * ドラッグアンドドロップによる並べ替え機能を含む
 */
export const MenuitemListViewResults = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { confirm } = useConfirmation()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { condition: queryCondition, setSearchWord } =
    useMenuitemsQueryCondition()

  const [selectedMenuitems, setSelectedMenuitems] = useState<string[]>([])

  const [searchInputValue, setSearchInputValue] = useState<string>(
    queryCondition.searchWord || '',
  )
  const debouncedSearchWord = useDebounce<string>(searchInputValue, 500)
  useEffect(() => {
    startTransition(() => {
      setSearchWord(debouncedSearchWord)
    })
  }, [debouncedSearchWord, setSearchWord])

  // 抽象化のためのカスタムフック類
  const { updateProductSoldOut } = useUpdateProductSoldOut()
  const { updateProductVisibility } = useUpdateProductVisibility()

  const menuQuery = useQueryMenu()
  const { currency_code: currencyCode } = menuQuery.data

  const menuitemsQuery = useQueryMenuitems(queryCondition)
  const { data: actualMenuItems } = menuitemsQuery

  const genresAllQuery = useQueryAllGenres()
  const genres = genresAllQuery.data

  const { isPending: isDeleting, mutate: deletionMutate } =
    useMutationDeleteMenuitems()

  // ドラッグアンドドロップ機能のフックを使用
  const { menuitems, isReordering, sensors, onDragEnd, collisionDetection } =
    useDraggableMenuitems(actualMenuItems)

  const handleSelectAllMenuitems = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedMenuitems(
      event.target.checked ? menuitems.map((menuitem) => menuitem.id) : [],
    )
  }

  const handleSelectOneMenuitem = (menuitemId: string): void => {
    if (selectedMenuitems.includes(menuitemId)) {
      setSelectedMenuitems((prevSelected) =>
        prevSelected.filter((id) => id !== menuitemId),
      )
    } else {
      setSelectedMenuitems((prevSelected) => [...prevSelected, menuitemId])
    }
  }

  const deleteSelectedItems = async () => {
    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: t('ui_products:multiple products deletion confirmation', {
        count: selectedMenuitems.length,
      }),
      title: t('Deletion'),
      variant: 'danger',
    })
    if (!yes) {
      return
    }

    deletionMutate(selectedMenuitems, {
      onSuccess: (count) => {
        queryClient.invalidateQueries({ queryKey: queryKeys.menuitemsAll() })
        const message = t('ui_products:Deleted products', { count })
        enqueueSnackbar(message, {
          variant: 'success',
        })
        setSelectedMenuitems([])
      },
    })
  }

  const enableBulkOperations = selectedMenuitems.length > 0
  const isSomeMenuitemSelected =
    selectedMenuitems.length > 0 && selectedMenuitems.length < menuitems.length
  const isEveryMenuitemSelected =
    menuitems.length > 0 && selectedMenuitems.length === menuitems.length
  const isFiltering = !!queryCondition.searchWord
  const hasMenuItems = menuitems.length > 0

  if (!(isFiltering || hasMenuItems)) {
    return (
      <Typography color="textPrimary">
        {t(
          'ui_products:No products have been registered yet. Click the button to register your first product.',
        )}
      </Typography>
    )
  }

  return (
    <>
      <Box sx={styles.searchArea}>
        <TextField
          sx={styles.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: searchInputValue ? (
              <IconButton
                onClick={() => {
                  setSearchInputValue('')
                  startTransition(() => {
                    // skip debounsing and show all items immediately
                    setSearchWord('')
                  })
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            ) : null,
          }}
          onChange={(e) => {
            setSearchInputValue(e.target.value)
          }}
          placeholder={t('Search')}
          size="small"
          value={searchInputValue}
          variant="outlined"
        />
      </Box>

      {/* ドラッグアンドドロップで並べ替え可能な商品一覧 */}
      <Card sx={styles.tableArea}>
        {enableBulkOperations && (
          <Box sx={styles.bulkOperations}>
            <Box sx={styles.bulkActions}>
              <Checkbox
                checked={isEveryMenuitemSelected}
                sx={styles.bulkActionCheckbox}
                indeterminate={isSomeMenuitemSelected}
                onChange={handleSelectAllMenuitems}
              />
              <Button
                disabled={isDeleting}
                variant="outlined"
                sx={styles.bulkAction}
                onClick={deleteSelectedItems}
                size="small"
              >
                {t('Delete')}
              </Button>
            </Box>
          </Box>
        )}
        <Paper
          css={css`
            overflow-x: auto;
          `}
        >
          <Box minWidth={700}>
            <DndContext
              sensors={sensors}
              collisionDetection={collisionDetection}
              onDragEnd={onDragEnd}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" width="50px">
                      {/* ドラッグハンドルの列 */}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isEveryMenuitemSelected}
                        indeterminate={isSomeMenuitemSelected}
                        onChange={handleSelectAllMenuitems}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ ...styles.cellCommon, minWidth: '230px' }}
                      sortDirection="desc"
                    >
                      名前
                    </TableCell>
                    <TableCell sx={{ ...styles.cellCommon, minWidth: '130px' }}>
                      ジャンル
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ ...styles.cellCommon, minWidth: '80px' }}
                    >
                      値段
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...styles.cellCommon,
                        minWidth: '60px',
                        paddingLeft: 2,
                      }}
                    >
                      非表示
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ ...styles.cellCommon, minWidth: '60px' }}
                    >
                      売り切れ
                    </TableCell>
                    <TableCell sx={{ ...styles.cellCommon, minWidth: '250px' }}>
                      説明
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SortableContext
                    items={menuitems.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {menuitems.map((menuitem) => {
                      const genreName =
                        genres.find((genre) => genre.id === menuitem.genre)
                          ?.name || '(未指定)'

                      return (
                        <SortableMenuitemRow
                          key={menuitem.id}
                          menuitem={menuitem}
                          genreName={genreName}
                          currencyCode={currencyCode}
                          onUpdateVisibility={updateProductVisibility}
                          onUpdateSoldOut={updateProductSoldOut}
                          disabled={isFiltering || isReordering}
                          selected={selectedMenuitems.includes(menuitem.id)}
                          onSelect={handleSelectOneMenuitem}
                        />
                      )
                    })}
                  </SortableContext>
                </TableBody>
              </Table>
            </DndContext>
          </Box>
        </Paper>
      </Card>
    </>
  )
}

/**
 * ドラッグ可能な商品行コンポーネント
 */
type SortableMenuitemRowProps = {
  menuitem: Menuitem
  genreName: string
  currencyCode: CurrencyCode
  onUpdateVisibility: (id: string, name: string, isHidden: boolean) => void
  onUpdateSoldOut: (id: string, name: string, isSoldOut: boolean) => void
  disabled: boolean
  selected: boolean
  onSelect: (menuitemId: string) => void
}

const SortableMenuitemRow = ({
  menuitem,
  genreName,
  currencyCode,
  onUpdateVisibility,
  onUpdateSoldOut,
  disabled,
  selected,
  onSelect,
}: SortableMenuitemRowProps) => {
  // これらのuseSortableから提供される値が、テーブル行をエンハンスしてドラッグ可能にしてくれる
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: menuitem.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative' as const,
  }

  return (
    <TableRow ref={setNodeRef} style={style} hover={true}>
      <TableCell padding="none">
        <Box
          {...attributes}
          {...listeners}
          sx={[styles.dragHandle, disabled && styles.dragHandleDisabled]}
        >
          <DragIndicator fontSize="small" />
        </Box>
      </TableCell>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onChange={() => onSelect(menuitem.id)}
          value={selected}
        />
      </TableCell>
      <TableCell sx={styles.cellCommon}>
        <Box display="flex" alignItems="center">
          <Avatar
            sx={styles.photo}
            src={getImgixSrc(menuitem.imgix_filename, {
              width: 80,
            })}
            variant="rounded"
          />
          <Link
            color="inherit"
            component={RouterLink}
            to={`/app/management/menuitems/${menuitem.id}`}
            variant="h6"
          >
            <ClampText>{menuitem.name}</ClampText>
          </Link>
        </Box>
      </TableCell>
      <TableCell sx={styles.cellCommon}>
        <Link
          color="inherit"
          component={RouterLink}
          to={`/app/management/genres/${menuitem.genre}`}
          variant="h6"
        >
          <ClampText>{genreName}</ClampText>
        </Link>
      </TableCell>
      <TableCell align="right" sx={styles.cellCommon}>
        <CurrencyText currencyCode={currencyCode} value={menuitem.price} />
      </TableCell>
      <TableCell
        sx={{
          ...styles.cellCommon,
          paddingLeft: 2,
        }}
      >
        <Switch
          checked={menuitem.is_hidden}
          color="secondary"
          onChange={() =>
            onUpdateVisibility(menuitem.id, menuitem.name, !menuitem.is_hidden)
          }
          value={menuitem.is_hidden}
        />
      </TableCell>
      <TableCell sx={styles.cellCommon}>
        <Switch
          disabled={menuitem.is_hidden}
          checked={menuitem.sold_out}
          color="secondary"
          onChange={() =>
            onUpdateSoldOut(menuitem.id, menuitem.name, !menuitem.sold_out)
          }
          value={menuitem.sold_out}
        />
      </TableCell>
      <TableCell sx={styles.cellCommon}>
        <ClampText>{menuitem.description}</ClampText>
      </TableCell>
    </TableRow>
  )
}

const styles = {
  searchArea: { background: 'white', width: '100%', marginTop: 2 },
  tableArea: { marginTop: 2 },
  // 全てのセルのパディングを減らす
  cellCommon: {
    paddingX: 1,
    paddingY: 0.75,
  },
  queryField: {
    width: '100%',
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: '43px',
    paddingRight: 0.5,
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: 'background.default',
  },
  bulkActionCheckbox: {
    margin: '-3px 0',
  },
  bulkAction: {
    marginLeft: 2,
  },
  photo: {
    height: 42,
    width: 42,
    marginRight: 2,
  },
  dragHandle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text.secondary',
    borderRadius: 1,
    marginLeft: 1,
    padding: '4px',
    touchAction: 'none',
    cursor: 'pointer',
    '&:hover': {
      pointer: 'cursor',
      color: 'primary.main',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  dragHandleDisabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
} as const satisfies SxPropStyles
