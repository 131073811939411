import type { GenreLite } from '@/features/api/types'
import type { GenresQueryCondition } from '@/features/genreList/useGenresQueryCondition'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export function useQueryGenres(condition: GenresQueryCondition) {
  return useSuspenseQuery({
    queryKey: queryKeys.genres(condition),
    queryFn: async (): Promise<GenreLite[]> => {
      const fetched = await trpcClient.genre.getList.query({
        sortOption: {
          orderBy: condition.orderBy,
          order: condition.order,
        },
        searchOption: {
          searchWord: condition.searchWord ?? '',
        },
      })
      return fetched.map((record) => ({
        description: record.description,
        id: record.id,
        is_hidden: record.isHidden,
        name: record.name,
        order_num: record.orderNum,
        product_count: record.productCount,
      }))
    },
  })
}
