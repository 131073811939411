import { FieldTitle } from '@/components/FieldTitle'
import { FormikErrors } from '@/components/FormikErrors'
import type { Menu } from '@/features/api/types'
import Page from '@/features/layout/Page'
import { MenuSettingHeader } from '@/features/menuSetting/MenuSettingHeader'
import QuillEditor from '@/features/menuSetting/QuillEditor'
import { useMutationUpdateMenu } from '@/features/menuSetting/api/useMutationUpdateMenu'
import { useQueryMenu } from '@/features/menuSetting/api/useQueryMenu'
import { googleFontSettings } from '@/features/menuSetting/googleFontSettings.ts'
import { themeModeSettings } from '@/features/menuSetting/themeModeSettings.ts'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload.ts'
import { css } from '@emotion/react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Yup from 'yup'
import { currencySettings } from '../currency/_do_not_edit_currencySettings.ts'

const styles = {
  container: {
    paddingTop: 3,
    paddingBottom: 3,
  },
}

const fontOptions = Object.entries(googleFontSettings).map(([value, name]) => ({
  value,
  label: name,
}))

const themeModeOptions = Object.entries(themeModeSettings).map(
  ([value, name]) => ({
    value,
    label: name,
  }),
)

const currencyOptions = Object.entries(currencySettings).map(
  ([currencyCode, { code, name, symbol }]) => ({
    value: currencyCode,
    label: `${code} / ${name} / ${symbol}`,
  }),
)

const MenuSetting: FC = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const menuQuery = useQueryMenu()
  const menu = menuQuery.data

  const initialValues = {
    currency_code: menu.currency_code,
    description: menu.description,
    font_type: menu.font_type,
    id: menu.id,
    shop_name: menu.shop_name,
    shop_postal_code: menu.shop_postal_code,
    theme_mode: menu.theme_mode,
  }

  const { isPending: isUpdating, mutate: mutateUpdate } =
    useMutationUpdateMenu()

  const {
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = useFormik<Menu>({
    enableReinitialize: true,
    initialValues,
    onSubmit: (formValues) => {
      mutateUpdate(formValues, {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: queryKeys.menu() })
          enqueueSnackbar(t('Updated'), { variant: 'success' })
        },
        onError: (e) => {
          const customError = unwrapCustomErrorPayload(e)
          if (customError && customError.type === 'menu.invalid_postal_code') {
            enqueueSnackbar('郵便番号が正しくありません', { variant: 'error' })
            return
          }
          enqueueSnackbar(t('Error occurred'), { variant: 'error' })
        },
      })
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().max(5000),
      shop_name: Yup.string().max(100),
      // biome-ignore lint:
      shop_postal_code: Yup.string().matches(/^\d{3}-\d{4}$/, {
        message: t('ui_general:Invalid postal code format'),
      }),
      theme_mode: Yup.string().required('必須です'),
    }),
  })

  return (
    <Page sx={styles.container} title={t('General settings')}>
      <Container maxWidth={false}>
        <MenuSettingHeader />
      </Container>
      <Box mt={3}>
        <Container maxWidth={false}>
          <form onSubmit={handleSubmit}>
            <Card
              css={css`
                overflow: visible;
              `}
            >
              <CardContent>
                <Grid container={true} spacing={3}>
                  <Grid size={{ md: 6, xs: 12 }}>
                    <FieldTitle
                      title={t('ui_general:Store name')}
                      tooltipText={t('ui_general:Hint for seo')}
                    />
                    <Box mt={1}>
                      <TextField
                        error={Boolean(touched.shop_name && errors.shop_name)}
                        fullWidth={true}
                        name="shop_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.shop_name}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid size={{ md: 6, xs: 12 }}>
                    <FieldTitle
                      title={t('ui_general:Store postal code')}
                      tooltipText={t('ui_general:Hint for seo')}
                    />
                    <Box mt={1}>
                      <TextField
                        error={Boolean(
                          touched.shop_postal_code && errors.shop_postal_code,
                        )}
                        fullWidth={true}
                        name="shop_postal_code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder="123-5678"
                        value={values.shop_postal_code}
                        variant="outlined"
                      />
                    </Box>
                  </Grid>

                  <Grid size={{ md: 6, xs: 12 }}>
                    <FieldTitle title={t('ui_general:Font setting')} />
                    <Box position="relative" mt={1}>
                      <TextField
                        error={Boolean(touched.font_type && errors.font_type)}
                        fullWidth={true}
                        name="font_type"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select={true}
                        slotProps={{ select: { native: true } }}
                        value={values.font_type}
                        variant="outlined"
                      >
                        {fontOptions.map((fontOption) => (
                          <option
                            key={fontOption.value}
                            value={fontOption.value}
                          >
                            {fontOption.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid size={{ md: 6, xs: 12 }}>
                    <FieldTitle title="テーマ" />
                    <Box position="relative" mt={1}>
                      <TextField
                        error={Boolean(touched.theme_mode && errors.theme_mode)}
                        fullWidth={true}
                        name="theme_mode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select={true}
                        slotProps={{ select: { native: true } }}
                        value={values.theme_mode}
                        variant="outlined"
                      >
                        {themeModeOptions.map((themeOption) => (
                          <option
                            key={themeOption.value}
                            value={themeOption.value}
                          >
                            {themeOption.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid size={{ md: 6, xs: 12 }}>
                    <FieldTitle title={t('Currency')} />
                    <Box mt={1}>
                      <TextField
                        error={Boolean(
                          touched.currency_code && errors.currency_code,
                        )}
                        fullWidth={true}
                        name="currency_code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        select={true}
                        SelectProps={{ native: true }}
                        value={values.currency_code}
                        variant="outlined"
                      >
                        {currencyOptions.map((currencyOption) => (
                          <option
                            key={currencyOption.value}
                            value={currencyOption.value}
                          >
                            {currencyOption.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>

                  <Grid size={12}>
                    <FieldTitle
                      title={t('ui_general:General description')}
                      tooltipText={t('ui_general:Hint for general description')}
                    />
                    <Box mt={1}>
                      <QuillEditor
                        darkMode={values.theme_mode === 'dark'}
                        defaultValue={values.description}
                        onChange={(newValue) => {
                          setFieldValue('description', newValue)
                          // handle manually as Quill doesn't accept `name` attribute
                          setFieldTouched('description', true)
                        }}
                        redBorder={Boolean(
                          touched.description && errors.description,
                        )}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Divider
                  css={css`
                    margin-top: 1rem;
                  `}
                />
                <FormikErrors touched={touched} errors={errors} />
                <Box mt={2} justifyContent="flex-end" display="flex">
                  <Button
                    disabled={isUpdating}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    {t('Update')}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        </Container>
      </Box>
    </Page>
  )
}

export default MenuSetting
