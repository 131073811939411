import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router'
import invariant from 'tiny-invariant'
import { useMutationDuplicateMenuitem } from '../api/useMutationDuplicateMenuitem.js'

export function useDuplicateMenuitem() {
  const { confirm } = useConfirmation()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { isPending: isDuplicating, mutate: mutateDuplicate } =
    useMutationDuplicateMenuitem()

  const duplicateMenuitem = async (
    menuitemId: string,
    menuitemName: string,
  ) => {
    invariant(menuitemId, '複製対象のメニューアイテムのIDがありません')

    const yes = await confirm({
      buttonTextNegative: 'キャンセル',
      buttonTextPositive: '複製',
      description: `「${menuitemName}」を複製してもよろしいですか？`,
      title: '',
      variant: 'danger',
    })
    if (!yes) {
      return
    }

    mutateDuplicate(menuitemId, {
      onSuccess: (duplicatedProduct) => {
        queryClient.invalidateQueries({ queryKey: queryKeys.menuitemsAll() })
        enqueueSnackbar('複製しました', { variant: 'success' })
        navigate(`/app/management/menuitems/${duplicatedProduct.id}`, {
          replace: true,
        })
      },
      onError: () => {
        enqueueSnackbar('複製中にエラーが発生しました', { variant: 'error' })
      },
    })
  }

  return {
    duplicateMenuitem,
    isDuplicating,
  }
}
