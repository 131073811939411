import type { Menuitem } from '@/features/api/types'
import type { MenuitemsQueryCondition } from '@/features/menuitemList/useMenuitemsQueryCondition'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export const useQueryMenuitems = (condition: MenuitemsQueryCondition) => {
  return useSuspenseQuery({
    queryKey: queryKeys.menuitems(condition),
    queryFn: async (): Promise<Menuitem[]> => {
      const fetched = await trpcClient.product.getList.query({
        sortOption: {
          orderBy: condition.orderBy,
          order: condition.order,
        },
        searchOption: {
          searchWord: condition.searchWord ?? '',
        },
      })
      return fetched.map((record) => ({
        description: record.description,
        description_detail: record.descriptionDetail,
        genre: record.genreId ?? '', // TODO: string or nullにしたい
        id: record.id,
        imgix_filename: record.imgixFilename,
        is_hidden: record.isHidden,
        is_price_hidden: record.isPriceHidden,
        name: record.name,
        order_num: record.orderNum,
        price: record.price,
        sold_out: record.soldOut,
      }))
    },
  })
}
