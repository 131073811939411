import { ClampText } from '@/components/ClampText.tsx'
import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { useMutationBulkDeleteGenres } from '@/features/genreList/api/useMutationBulkDeleteGenres'
import { useQueryGenres } from '@/features/genreList/api/useQueryGenres'
import { useDraggableGenres } from '@/features/genreList/orderGenre/useDraggableGenres'
import { useGenresQueryCondition } from '@/features/genreList/useGenresQueryCondition'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { useDebounce } from '@/utils/useDebounce'
import { DndContext } from '@dnd-kit/core'
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { css } from '@emotion/react'
import { DragIndicator } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { type ChangeEvent, startTransition, useEffect, useState } from 'react'
import { Search as SearchIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router'

/**
 * ジャンル一覧表示コンポーネント
 * ドラッグアンドドロップによる並べ替え機能を含む
 */
export const GenreListViewResults = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { condition: queryCondition, setSearchWord } = useGenresQueryCondition()
  const { confirm } = useConfirmation()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedGenres, setSelectedGenres] = useState<string[]>([])

  const [searchInputValue, setSearchInputValue] = useState<string>(
    // restore previous value on navigation
    queryCondition.searchWord || '',
  )
  const debouncedSearchWord = useDebounce<string>(searchInputValue, 500)
  useEffect(() => {
    startTransition(() => {
      setSearchWord(debouncedSearchWord)
    })
  }, [debouncedSearchWord, setSearchWord])

  const genresQuery = useQueryGenres(queryCondition)
  const { data: actualGenres } = genresQuery

  const { isPending: isDeleting, mutate: deletionMutate } =
    useMutationBulkDeleteGenres()

  // ドラッグアンドドロップ機能のフックを使用
  const { genres, isReordering, sensors, onDragEnd, collisionDetection } =
    useDraggableGenres(actualGenres)

  const handleSelectAllGenres = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    setSelectedGenres(
      event.target.checked ? genres.map((genre) => genre.id) : [],
    )
  }

  const handleSelectOneGenre = (genreId: string): void => {
    if (selectedGenres.includes(genreId)) {
      setSelectedGenres((prevSelected) =>
        prevSelected.filter((id) => id !== genreId),
      )
    } else {
      setSelectedGenres((prevSelected) => [...prevSelected, genreId])
    }
  }

  const deleteSelectedItems = async () => {
    const message = t('ui_genres:multiple genres deletion confirmation', {
      count: selectedGenres.length,
    })
    const yes = await confirm({
      buttonTextNegative: t('Cancel'),
      buttonTextPositive: t('Delete'),
      description: message,
      title: t('Deletion'),
      variant: 'danger',
    })
    if (yes) {
      deletionMutate(selectedGenres, {
        onSuccess: (result) => {
          queryClient.invalidateQueries({ queryKey: queryKeys.genresAll() })
          const message = t('ui_genres:Deleted genres', {
            count: result.count,
          })
          enqueueSnackbar(message, { variant: 'success' })
          setSelectedGenres([])
        },
        onError: () => {
          enqueueSnackbar('エラーが発生しました', { variant: 'error' })
        },
      })
    }
  }

  const enableBulkOperations = selectedGenres.length > 0
  const isSomeGenreSelected =
    selectedGenres.length > 0 && selectedGenres.length < genres.length
  const isEveryGenreSelected =
    genres.length > 0 && selectedGenres.length === genres.length
  const isFiltering = !!queryCondition.searchWord
  const hasGenres = genres.length > 0

  if (!(isFiltering || hasGenres)) {
    return (
      <Typography color="textPrimary">
        {t(
          'ui_genres:No genres have been registered yet. Click the button to register your first genre.',
        )}
      </Typography>
    )
  }

  return (
    <>
      <Box sx={styles.searchArea}>
        <TextField
          sx={styles.queryField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: searchInputValue ? (
              <IconButton
                onClick={() => {
                  setSearchInputValue('')
                  // skip debounsing and show all items immediately
                  setSearchWord('')
                }}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            ) : null,
          }}
          onChange={(e) => {
            setSearchInputValue(e.target.value)
          }}
          placeholder={t('Search')}
          size="small"
          value={searchInputValue}
          variant="outlined"
        />
      </Box>
      <Card sx={styles.tableArea}>
        {enableBulkOperations && (
          <Box sx={styles.bulkOperations}>
            <Box sx={styles.bulkActions}>
              <Checkbox
                checked={isEveryGenreSelected}
                sx={styles.bulkActionCheckbox}
                indeterminate={isSomeGenreSelected}
                onChange={handleSelectAllGenres}
              />
              <Button
                disabled={isDeleting}
                variant="outlined"
                sx={styles.bulkAction}
                onClick={deleteSelectedItems}
                size="small"
              >
                {t('Delete')}
              </Button>
            </Box>
          </Box>
        )}
        <Paper
          css={css`
          overflow-x: auto;
        `}
        >
          <Box minWidth={700}>
            <DndContext
              sensors={sensors}
              collisionDetection={collisionDetection}
              onDragEnd={onDragEnd}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" width="50px">
                      {/* ドラッグハンドルの列 */}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isEveryGenreSelected}
                        indeterminate={isSomeGenreSelected}
                        onChange={handleSelectAllGenres}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ ...styles.cellCommon, minWidth: '150px' }}
                      sortDirection="desc"
                    >
                      {t('Name')}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        ...styles.cellCommon,
                        minWidth: '70px',
                      }}
                      sortDirection="desc"
                    >
                      商品数
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ ...styles.cellCommon, minWidth: '70px' }}
                    >
                      {t('Hidden')}
                    </TableCell>
                    <TableCell
                      sx={{ ...styles.cellCommon, minWidth: '250px' }}
                      sortDirection="desc"
                    >
                      {t('Additional description')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SortableContext
                    items={genres.map((item) => item.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {genres.map((genre) => {
                      const isGenreSelected = selectedGenres.includes(genre.id)

                      return (
                        <SortableGenreRow
                          key={genre.id}
                          genre={genre}
                          selected={isGenreSelected}
                          onSelect={handleSelectOneGenre}
                          disabled={isFiltering || isReordering}
                        />
                      )
                    })}
                  </SortableContext>
                </TableBody>
              </Table>
            </DndContext>
          </Box>
        </Paper>
      </Card>
    </>
  )
}

/**
 * ドラッグ可能なジャンル行コンポーネント
 */
type SortableGenreRowProps = {
  genre: {
    id: string
    name: string
    is_hidden: boolean
    description: string
    product_count: number
  }
  selected: boolean
  onSelect: (genreId: string) => void
  disabled: boolean
}

const SortableGenreRow = ({
  genre,
  selected,
  onSelect,
  disabled,
}: SortableGenreRowProps) => {
  // これらのuseSortableから提供される値が、テーブル行をエンハンスしてドラッグ可能にしてくれる
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: genre.id })

  const { t } = useTranslation()

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative' as const,
  }

  return (
    <TableRow ref={setNodeRef} style={style} hover={true}>
      <TableCell padding="none">
        <Box
          {...attributes}
          {...listeners}
          sx={[styles.dragHandle, disabled && styles.dragHandleDisabled]}
        >
          <DragIndicator fontSize="small" />
        </Box>
      </TableCell>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          onChange={() => onSelect(genre.id)}
          value={selected}
        />
      </TableCell>
      <TableCell sx={styles.cellCommon}>
        <Box minHeight={40} display="flex" alignItems="center">
          <Link
            color="inherit"
            component={RouterLink}
            to={`/app/management/genres/${genre.id}`}
            variant="h6"
          >
            <ClampText>{genre.name}</ClampText>
          </Link>
        </Box>
      </TableCell>
      <TableCell sx={styles.cellCommon} align="center">
        {genre.product_count}
      </TableCell>
      <TableCell sx={styles.cellCommon} align="center">
        {genre.is_hidden ? t('Yes') : ''}
      </TableCell>
      <TableCell
        sx={{
          ...styles.cellCommon,
          // こうしとかないとなぜかClampが効かない
          maxWidth: 250,
        }}
      >
        <ClampText>{genre.description}</ClampText>
      </TableCell>
    </TableRow>
  )
}

const styles = {
  searchArea: { background: 'white', width: '100%', marginTop: 2 },
  tableArea: { marginTop: 2 },
  // 全てのセルのパディングを減らす
  cellCommon: {
    paddingX: 1,
    paddingY: 0.75,
  },
  queryField: {
    width: '100%',
  },
  bulkOperations: {
    position: 'relative',
  },
  bulkActions: {
    paddingLeft: '43px',
    paddingRight: 0.5,
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    backgroundColor: 'background.default',
  },
  bulkActionCheckbox: {
    margin: '-3px 0',
  },
  bulkAction: {
    marginLeft: 2,
  },
  dragHandle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'text.secondary',
    borderRadius: 1,
    marginLeft: 1,
    padding: '4px',
    touchAction: 'none',
    cursor: 'pointer',
    '&:hover': {
      pointer: 'cursor',
      color: 'primary.main',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  dragHandleDisabled: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  description: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}
