import { AdminHeader } from '@/features/admin/AdminHeader.tsx'
import { EmailChange } from '@/features/admin/emailChange/EmailChange.tsx'
import { ProductStats } from '@/features/admin/productStats/ProductStats.tsx'
import { UserStats } from '@/features/admin/userStats/UserStats.tsx'
import Page from '@/features/layout/Page.tsx'
import type { SxPropStyles } from '@/libraries/mui/muiTypes.ts'
import { Container, Grid } from '@mui/material'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
  card: {
    marginY: 2,
    padding: 2,
  },
} satisfies SxPropStyles

export const Admin = () => {
  return (
    <Page sx={styles.root} title="管理者">
      <Container maxWidth={false}>
        <AdminHeader />
        <Grid container={true} spacing={2} mt={2}>
          <Grid size={{ md: 12 }}>
            <UserStats />
          </Grid>
          <Grid size={{ md: 12 }}>
            <ProductStats />
          </Grid>
          <Grid size={{ md: 6 }}>
            <EmailChange />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}
