import { useMutationUploadMenuitemImage } from '@/features/menuitemDetail/api/useMutationCreateMenuitemImage'
import { HTTPError } from 'ky'
import { useSnackbar } from 'notistack'
import invariant from 'tiny-invariant'

type UseUploadMenuitemImageProps = {
  onSuccess: (fileName: string) => void
}

export function useUploadMenuitemImage({
  onSuccess,
}: UseUploadMenuitemImageProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { isPending: isImageUploading, mutate: uploadImageMutation } =
    useMutationUploadMenuitemImage()

  const uploadImage = async (files: File[]) => {
    const file = files[0]
    invariant(file, 'ファイルがありません')
    uploadImageMutation(file, {
      onSuccess: ({ fileName }) => {
        onSuccess(fileName)
      },
      onError: async (e) => {
        if (!(e instanceof HTTPError)) {
          enqueueSnackbar('ファイルのアップロード時にエラーが発生しました', {
            variant: 'error',
          })
          return
        }
        switch ((await e.response.json()).message) {
          case 'bad mime type':
            enqueueSnackbar(
              'サポートされていない画像形式です。使用可能な形式はJPEG/PNG/WEBP/HEICの４種類です。',
              { variant: 'error' },
            )
            break
          case 'file is too large':
            enqueueSnackbar('ファルサイズが上限の10MBを超えています', {
              variant: 'error',
            })
            break
          default:
            enqueueSnackbar('ファイルのアップロード時にエラーが発生しました', {
              variant: 'error',
            })
        }
      },
    })
  }

  return {
    uploadImage,
    isImageUploading,
  }
}
