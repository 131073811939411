import { useUserInfo } from '@/features/auth/state/authStatus'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MailVerifyButton } from './MailVerifyButton.tsx'
import { PasswordResetButton } from './PasswordResetButton.tsx'

interface Props {
  className?: string
  currentEmail: string
}

export const AccountViewGeneral: FC<Props> = ({
  className,
  currentEmail,
  ...rest
}) => {
  const userInfo = useUserInfo()

  const { t } = useTranslation()

  return (
    <form>
      <Card className={className} {...rest}>
        <CardHeader title={t('ui_account:Profile')} />
        <Divider />
        <CardContent>
          <Grid container={true} spacing={4}>
            <Grid size={{ xs: 12 }}>
              <Typography variant="subtitle2" color="textSecondary">
                {t('Email')}
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {currentEmail}
              </Typography>
              <Typography variant="caption" color="textPrimary">
                (
                {t(
                  'ui_account:Please contact support if you want to change email address or delete account.',
                )}
                )
              </Typography>
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  {t('パスワード')}
                </Typography>
                <Box mt={1}>
                  <PasswordResetButton email={currentEmail} />
                </Box>
              </Box>
            </Grid>
            {!userInfo.firebaseUser.emailVerified && (
              <Grid size={{ xs: 12 }}>
                <Divider />
                <Typography variant="caption" color="textPrimary">
                  <span aria-label="caution">⚠️</span>
                  {t(
                    'ui_account:Your email address is not verified yet. Press the button and click the link in the received mail.',
                  )}
                  <br />
                </Typography>
                <MailVerifyButton />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </form>
  )
}
