import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

type ReorderMenuitemsParams = {
  movedItemId: string
  previousItemId: string | null
  nextItemId: string | null
}

export function useMutationOrderProduct() {
  return useMutation({
    mutationFn: async (params: ReorderMenuitemsParams): Promise<void> => {
      const { movedItemId, previousItemId, nextItemId } = params

      await trpcClient.product.orderProduct.mutate({
        productId: movedItemId,
        prevProductId: previousItemId,
        nextProductId: nextItemId,
      })
    },
  })
}
