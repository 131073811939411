import { Box, type SxProps, type Theme } from '@mui/material'
import Quill, { type QuillOptions } from 'quill'
import { useEffect, useId } from 'react'
import 'quill/dist/quill.snow.css'
import invariant from 'tiny-invariant'

type Props = {
  /**
   * ダークモード時には背景色を暗くしないと見えづらいため
   */
  darkMode: boolean
  /**
   * `<p><br></p>`のような形式のHTML文字列。初期値として利用する。
   */
  defaultValue: string
  /**
   * バリデーションエラー時などに枠線を赤くするため
   */
  redBorder?: boolean
  /**
   * シンプルさを保つため、Quillはアンコントロールドに使用する。
   * onChangeはユーザーによる変更を一方的に親に通知するためのコールバック。
   */
  onChange: (value: string) => void
}

const quillOptions: QuillOptions = {
  theme: 'snow',
  // ここで許可されていないと、いくらツールバーに表示(modules.toolbarに設定)されていても、使えない
  formats: ['bold', 'underline', 'color', 'align', 'link'],
  modules: {
    toolbar: [
      [
        'bold',
        'underline',
        {
          // Prompt
          // `ユーザーが文字色を設定するUIを作っています。文字色に最適な色をいくつか選んでもらえますか？背景は常に白です。今の候補は以下ですが、もう何色か追加したいです。'#000001', '#646464', '#D32F2F', '#1976D2', '#388E3C'`
          color: [
            '#000001',
            '#ffffff',
            '#646464',
            '#D32F2F',
            '#F44336',
            '#1976D2',
            '#2196F3',
            '#388E3C',
            '#4CAF50',
            '#00796B',
            '#009688',
            '#C2185B',
            '#E91E63',
            '#F57C00',
            '#FF9800',
            '#303F9F',
            '#9FA8DA',
            '#512DA8',
            '#9575CD',
          ],
        },
      ],
      [{ align: [] }],
      ['link'],
      ['clean'],
    ],
  },
}

/**
 * QuillをReactで使うためのラッパーコンポーネント
 *
 * React管理のDOMをQuillが乗っ取るという点で行儀は良くないが、シンプルさを優先した。
 */
const QuillEditor = (props: Props) => {
  const { darkMode, defaultValue, redBorder = false, onChange } = props

  // 乗っ取るdivに一意なIDを付与する
  const targetElementId = useId()

  // Suspenseを使っているのでありえないが、データ消失を防ぐため一応確認しておく
  invariant(defaultValue !== undefined)

  // biome-ignore lint/correctness/useExhaustiveDependencies: 2回以上初期化すると絶対にバグるので
  useEffect(() => {
    const quill = new Quill(
      `[id="${targetElementId}"]`, // `#id`の形式で書くとエスケープ関連の問題によりエラーになるので注意
      quillOptions,
    )

    // HTML文字列をセットするときに、Quillの仕様に合わせて変換する
    // https://stackoverflow.com/a/61482805/6574720
    quill.setContents(quill.clipboard.convert({ html: defaultValue }))

    quill.on('text-change', (_a, _b, emitter) => {
      if (emitter !== 'user') {
        return
      }
      onChange(quill.root.innerHTML) // `<p><br></p>`のようなHTML文字列として取り出す
    })

    // イベントリスなの後始末などは特に不要であることを確認済み
  }, [])

  return (
    // Boxは赤枠線の表示と、QuillへのCSS適用の責務を負う
    <Box
      sx={[
        styles.container,
        redBorder && { border: '1px solid red' },
        // ダークモードの時は編集エリアの文字色と背景色を反転させてみやすくする
        darkMode && {
          '.ql-container .ql-editor': {
            color: '#fff',
            backgroundColor: '#333',
          },
        },
      ]}
    >
      <div id={targetElementId} />
    </Box>
  )
}

const styles = {
  container: (theme) => ({
    // 文字を打つ部分のフォントを調整
    '.ql-container .ql-editor': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
    },
  }),
} satisfies SxProps<Theme>

export default QuillEditor
