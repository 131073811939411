import type { MaybeNewMenuitem } from '@/features/api/types'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { unwrapCustomErrorPayload } from '@/libraries/trpc/unwrapCustomErrorPayload'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useMutationUpsertMenuitem } from '../api/useMutationUpsertMenuitem'

export function useUpsertMenuitem() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { isPending: isUpdating, mutate: mutateUpdate } =
    useMutationUpsertMenuitem()

  const upsertMenuitem = (formValues: MaybeNewMenuitem, isNew: boolean) => {
    mutateUpdate(formValues, {
      onSuccess: async (savedProductId) => {
        const message = isNew ? t('Added') : t('Updated')
        enqueueSnackbar(message, { variant: 'success' })
        await queryClient.invalidateQueries({
          queryKey: queryKeys.menuitem(savedProductId),
        })
        if (isNew) {
          navigate(`/app/management/menuitems/${savedProductId}/`, {
            replace: true,
          })
        }
      },
      onError: (e) => {
        const payload = unwrapCustomErrorPayload(e)
        switch (payload?.type) {
          case 'product.exceedsProductLimit':
            return enqueueSnackbar(
              `商品数の上限を超えました。上限は${payload.limit}個です。`,
              {
                variant: 'error',
              },
            )
          default:
            return enqueueSnackbar('エラーが発生しました', {
              variant: 'error',
            })
        }
      },
    })
  }

  return {
    upsertMenuitem,
    isUpdating,
  }
}
