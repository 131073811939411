import type { Menu } from '@/features/api/types'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationUpdateMenu() {
  return useMutation({
    mutationFn: (unsavedItem: Menu) => {
      return trpcClient.menu.update.mutate({
        currencyCode: unsavedItem.currency_code,
        description: unsavedItem.description,
        fontType: unsavedItem.font_type,
        id: unsavedItem.id,
        shopName: unsavedItem.shop_name,
        shopPostalCode: unsavedItem.shop_postal_code,
        themeMode: unsavedItem.theme_mode,
      })
    },
  })
}
