import { AccountDeletion } from '@/features/account/accountDeletion/AccountDeletion.tsx'
import { Subscription } from '@/features/account/subscription/Subscription'
import { useUserInfo } from '@/features/auth/state/authStatus'
import Page from '@/features/layout/Page'
import type { SxPropStyles } from '@/libraries/mui/muiTypes'
import { Box, Container, Grid } from '@mui/material'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AccountViewGeneral } from './AccountViewGeneral.tsx'
import { AccountViewHeader } from './AccountViewHeader.tsx'

const styles = {
  root: {
    minHeight: '100%',
    paddingTop: 3,
    paddingBottom: 3,
  },
} satisfies SxPropStyles

const AccountView: FC = () => {
  const { t } = useTranslation()
  const userInfo = useUserInfo()

  const currentEmail = userInfo.email
  const currentPlan = userInfo.plan

  return (
    <Page sx={styles.root} title={t('Account')}>
      <Container maxWidth={false}>
        <AccountViewHeader />
        <Box mt={3}>
          <Grid container={true} spacing={4}>
            <Grid size={{ xs: 12 }}>
              <AccountViewGeneral currentEmail={currentEmail} />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Subscription currentPlan={currentPlan} />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <AccountDeletion />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default AccountView
