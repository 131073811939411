import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

type ReorderGenresParams = {
  movedItemId: string
  newPosition: number
}

export function useMutationOrderGenre() {
  return useMutation({
    mutationFn: async (params: ReorderGenresParams): Promise<void> => {
      const { movedItemId, newPosition } = params

      await trpcClient.genre.orderGenre.mutate({
        genreId: movedItemId,
        newPosition: newPosition,
      })
    },
  })
}
