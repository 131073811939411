import type { Menuitem } from '@/features/api/types'
import { noCacheConfig } from '@/libraries/reactQuery/constants'
import { queryKeys } from '@/libraries/reactQuery/queryKeys'
import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useSuspenseQuery } from '@tanstack/react-query'

export function useQueryMenuitem(id: string, enabled: boolean) {
  return useSuspenseQuery({
    queryKey: queryKeys.menuitem(id),
    queryFn: async (): Promise<Menuitem | null> => {
      if (!enabled) {
        return null
      }
      const product = await trpcClient.product.get.query({ id })
      return {
        description: product.description,
        description_detail: product.descriptionDetail,
        genre: product.genreId ?? '', // string | null にしたい
        id: product.id,
        imgix_filename: product.imgixFilename,
        is_hidden: product.isHidden,
        is_price_hidden: product.isPriceHidden,
        name: product.name,
        order_num: product.orderNum,
        price: product.price,
        sold_out: product.soldOut,
      }
    },
    ...noCacheConfig,
  })
}
