import { Box } from '@mui/material';
import { YouTube } from './mdxComponents/YouTube.tsx'

#### 2025-03-07

- 商品やジャンルをドラッグアンドドロップで並べ替えできるようになりました🎉

<Box sx={{ maxWidth: '100%', mb:2}}>
  <YouTube url="https://www.youtube.com/embed/Drw9z4H5m24" />
</Box>

- 商品名が長い場合に、商品複製をするとエラーになる問題を修正しました。
- ドル建てでご契約いただいているお客様の3月14日以降の月額料金は$9.00となります。
  - 2025年2月13日に料金改定のお知らせをした際、料金を1,500円に統一するとお知らせしました。
  - しかし、決済システムの都合上、あとから通貨の変更ができない仕様であることが判明したため、新たに料金を設定したものです。

#### 2025-03-04

- 商品を複製できるようになりました🎉
  - 商品詳細ページで「複製する」ボタンを押すと、同じ内容の商品が新たに作成されます。
  - 既存の商品をベースにして新商品を作成したいときにご利用ください。
- 値段を非表示にできるようになりました🎉
  - 商品の詳細画面から「値段を表示しない」をチェックすることで、値段が表示されなくなります。
  - 無料であることが誰が見ても明らかであるものや、値段を表示すると不自然なものにご利用ください。

#### 2025-03-02

- ダークモード利用時に紙メニューまで暗くなってしまう問題を修正しました。

#### 2025-02-28

- ダークモードを利用できるようになりました🎉
  - 全般設定画面の「テーマ」のところで「ダーク」を指定することで、メニューを暗くすることができます。
  - お店が暗い環境で利用される場合や、目に優しい表示をご希望の場合にご利用ください。

#### 2025-02-13

**【重要】月額料金改定のお知らせ**

平素よりGoodMenuをご利用いただき、誠にありがとうございます。
このたび、サービスの継続を目的として、月額料金を改定させていただく運びとなりました。
大幅な変更となり誠に恐縮ではございますが、何卒ご理解いただけますと幸いです。

【新料金】
- 旧料金: 月額 500円 (税込) または $3.00 (税込)
- 新料金: 月額 1,500円 (税込)

【適用開始日】

2025年3月14日(金)以降の初回の請求日より適用

【料金改定の背景】

GoodMenuは2021年3月のサービス開始以来、なるべく導入しやすい価格で提供してまいりましたが、
一貫して開発・運営コストを大きく上回る赤字が続いてきました。
加えて昨今の物価高に伴い、背後で動作している関連サービスのコストも増加し続けている状況です。

こうした中でも長期的・安定的にサービスをご提供し、店舗様をしっかりとサポートし続けるために、
やむを得ず料金を大幅に改定させていただくことになりました。

今後もより良いサービスをお届けできるよう努めてまいります。
ご不明点やご質問がございましたら、いつでもお気軽にお問い合わせください。

#### 2025-01-22

- 商品の一覧画面から非表示や売り切れの設定を直接行えるようになりました 🎉「非表示」や「売り切れ」列のチェックボックスをクリックすることで、即座に設定を変更できます。
- 並替番号の入力欄を廃止しました。今後は一覧画面の「並べ替え」列のボタンをご利用ください。

#### 2025-01-14

- 商品やジャンルの並べ替えを一覧画面からボタンで行えるようになりました🎉
  - ボタンで並べ替えを行うと、自動で「並替番号」が振り直されます。なお、並替番号の入力欄は近日中に廃止する予定です。

#### 2025-01-09

紙メニューとして印刷した際に、商品がページをまたいで見切れてしまうケースがある問題を修正しました。

#### 2024-12-26

CSVインポート・エクスポートの機能を追加しました🎉
これにより、商品の一括登録・一括更新が可能になります。
インポート前には**必ずバックアップを作成**してからお試しください。

#### 2024-10-10

管理画面のUI/UXの細かな改善を行いました。

- 商品やジャンルの一覧画面で、名前が長い場合にセルが縦方向に無限に拡張して見づらくなっていました。このため、長文は省略表記するようにしました。
- 一覧画面の説明列を右端に移動し、より重要度の高い情報を左側に配置するようにしました。
- 商品の説明及び詳細説明が長文になる場合に、全体が見えず入力しづらい問題を解消しました。なお、デザインの制約上、商品の説明において改行は使用できないのは従来と変更ありません。
- これまでは商品を「非表示」にしたときに「売り切れ」の設定項目は隠されていましたが、迷いが生じるため常に表示するようにしました。

