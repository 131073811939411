import { resetPassword } from '@/features/auth/firebaseAuthUtils'
import { useConfirmation } from '@/features/confirmation/ConfirmationContext'
import { Button } from '@mui/material'
import { type FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  className?: string
  email: string
}

export const PasswordResetButton: FC<Props> = ({ email }) => {
  const { t } = useTranslation()
  const { confirm } = useConfirmation()
  const [isProcessing, setIsProcessing] = useState(false)

  const handleResetPassword = async () => {
    try {
      setIsProcessing(true)
      await resetPassword(email)

      await confirm({
        variant: 'info',
        title: t('Success'),
        description: t('ui_auth:Password reset success message'),
      })
    } catch (error) {
      console.error('Password reset error:', error)

      await confirm({
        variant: 'danger',
        title: t('Error'),
        description: t('パスワードリセットメールの送信に失敗しました。'),
      })
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <Button
      color="primary"
      disabled={isProcessing}
      onClick={handleResetPassword}
      variant="outlined"
      size="small"
    >
      {isProcessing ? t('処理中...') : t('パスワードをリセット')}
    </Button>
  )
}
