import { trpcClient } from '@/libraries/trpc/trpcClient.ts'
import { useMutation } from '@tanstack/react-query'

export function useMutationDuplicateMenuitem() {
  return useMutation({
    mutationFn: (duplicatingId: string) => {
      return trpcClient.product.duplicate.mutate({ id: duplicatingId })
    },
  })
}
