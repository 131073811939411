import { FieldTitle } from '@/components/FieldTitle'
import { FormikErrors } from '@/components/FormikErrors'
import type {
  GenreLite,
  MaybeNewMenuitem,
  Menuitem,
} from '@/features/api/types'
import { currencySettings } from '@/features/currency/_do_not_edit_currencySettings'
import {
  intPriceToRealPrice,
  realPriceToIntPrice,
} from '@/features/currency/_do_not_edit_currencyUtils'
import { useQueryMenu } from '@/features/menuSetting/api/useQueryMenu'
import { SquareDiv } from '@/features/menuitemDetail/SquareDiv'
import { useDeleteMenuitem } from '@/features/menuitemDetail/deleteMenuitem/useDeleteMenuitem'
import { useDuplicateMenuitem } from '@/features/menuitemDetail/duplicateMenuitem/useDuplicateMenuitem'
import { useUploadMenuitemImage } from '@/features/menuitemDetail/uploadMenuitemImage/useUploadMenuitemImage'
import { useUpsertMenuitem } from '@/features/menuitemDetail/upsertMenuitem/useUpsertMenuitem'
import { removeLineBreaks } from '@/utils/removeLineBreaks.ts'
import { css } from '@emotion/react'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import type { FC } from 'react'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'
import invariant from 'tiny-invariant'
// biome-ignore lint/style/noNamespaceImport: <explanation>
import * as Yup from 'yup'
import { getImgixSrc } from '../../libraries/imgix/getImgixSrc.ts'
import { SquareImage } from './SquareImage.tsx'

interface Props {
  existingMenuitem?: Menuitem
  genres: GenreLite[]
  isNew: boolean
}

export const MenuitemDetailViewForm: FC<Props> = ({
  existingMenuitem,
  genres,
  isNew,
  ...rest
}) => {
  const { t } = useTranslation()

  const menuQuery = useQueryMenu()
  const { currency_code: currencyCode } = menuQuery.data

  const { isUpdating, upsertMenuitem } = useUpsertMenuitem()

  const { deleteMenuitem, isDeleting } = useDeleteMenuitem()

  const { duplicateMenuitem, isDuplicating } = useDuplicateMenuitem()

  let initialValues: MaybeNewMenuitem
  if (isNew) {
    initialValues = {
      description: '',
      description_detail: '',
      genre: '',
      // id
      imgix_filename: '',
      is_hidden: false,
      is_price_hidden: false,
      name: '',
      price: 0,
      sold_out: false,
    }
  } else {
    initialValues = {
      // @ts-expect-error TS18048
      description: existingMenuitem.description,
      // @ts-expect-error TS18048
      description_detail: existingMenuitem.description_detail,
      // @ts-expect-error TS18048
      genre: existingMenuitem.genre,
      // @ts-expect-error TS18048
      id: existingMenuitem.id,
      // @ts-expect-error TS18048
      imgix_filename: existingMenuitem.imgix_filename,
      // @ts-expect-error TS18048
      is_hidden: existingMenuitem.is_hidden,
      // @ts-expect-error TS18048
      is_price_hidden: existingMenuitem.is_price_hidden,
      // @ts-expect-error TS18048
      name: existingMenuitem.name,
      // @ts-expect-error TS18048
      price: existingMenuitem.price,
      // @ts-expect-error TS18048
      sold_out: existingMenuitem.sold_out,
    }
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    values,
  } = useFormik<MaybeNewMenuitem>({
    enableReinitialize: true,
    initialValues,
    onSubmit: (formValues) => {
      upsertMenuitem(formValues, isNew)
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().max(200),
      description_detail: Yup.string().max(280),
      genre: Yup.string(),
      imgix_filename: Yup.string(),
      name: Yup.string().max(50).required(), // DB上は最長60文字であり+10文字分あるが、それは複製時に`【コピー】`を頭につけるための余分なので、フロントは50文字で制限する
      price: Yup.number().max(10000000).required(),
      is_price_hidden: Yup.boolean().required(),
      sold_out: Yup.boolean().required(),
    }),
  })

  const { isImageUploading, uploadImage } = useUploadMenuitemImage({
    onSuccess: (fileName) => {
      setFieldValue('imgix_filename', fileName)
    },
  })

  const image = (
    <div css={styles.image}>
      <SquareImage
        alt={t('Product')}
        src={getImgixSrc(values.imgix_filename, {
          width: 500,
        })}
      />
      <IconButton
        css={styles.imageDeleteButton}
        onClick={() => setFieldValue('imgix_filename', '')}
        size="small"
      >
        <CloseIcon fontSize="large" />
      </IconButton>
    </div>
  )

  const imageDropZone = (
    <Dropzone onDrop={uploadImage}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <SquareDiv {...getRootProps()}>
          <div
            css={css`
              ${styles.imageDropzone};
              border-color: ${isDragActive ? '#2196f3' : '#eeeeee'};
            `}
          >
            <input {...getInputProps()} accept="image/*" />
            {isImageUploading ? (
              'uploading...'
            ) : (
              <Typography>
                {t('ui_products:Tap')}
                <br />
                {t('or')}
                <br />
                {t('ui_products:Drag image here')}
              </Typography>
            )}
          </div>
        </SquareDiv>
      )}
    </Dropzone>
  )

  const emptySelection = {
    value: '',
    label: `(${t('Unspecified')})`,
  }

  const genreOptions = [
    emptySelection,
    ...genres.map((genre) => ({
      value: genre.id,
      label: genre.name,
    })),
  ]

  // 通貨の設定に応じて適切な価格表示がされるようにする
  const { realPrice, currencySymbol, decimalScale } = (() => {
    const currencySetting = currencySettings[currencyCode]
    invariant(currencySetting, 'currency setting was not found')
    const currencySymbol = currencySetting.symbol
    const decimalScale = currencySetting.decimalScale
    const realPrice = intPriceToRealPrice(values.price, decimalScale)

    return { realPrice, currencySymbol, decimalScale }
  })()

  return (
    <form onSubmit={handleSubmit} {...rest}>
      <Card>
        <CardContent>
          <div css={styles.grid}>
            {/* 商品名 */}
            <div>
              <FieldTitle title={`${t('Product name')} *`} />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth={true}
                  // TODO: 具体的な名前に修正したい
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={true}
                  value={values.name}
                  variant="outlined"
                />
              </Box>
            </div>

            {/* 価格 */}
            <div css={styles.priceCell}>
              <FieldTitle title={`${t('Price')} *`} />
              <Box mt={1}>
                <NumericFormat
                  // @ts-expect-error TS2590 ライブラリ側の対応待ち
                  className="mycurrency"
                  css={[
                    styles.priceInput,
                    Boolean(touched.price && errors.price) &&
                      styles.priceInputOnError,
                    values.is_price_hidden && styles.priceInputDisabled,
                  ]}
                  decimalScale={decimalScale}
                  disabled={values.is_price_hidden}
                  fixedDecimalScale={true}
                  inputMode="decimal"
                  onBlur={handleBlur}
                  onValueChange={(values) => {
                    const updatedRealPrice = values.floatValue
                    const updatedIntPrice = realPriceToIntPrice(
                      updatedRealPrice,
                      decimalScale,
                    )
                    setFieldValue('price', updatedIntPrice)
                  }}
                  prefix={`${currencySymbol} `}
                  thousandSeparator={true}
                  value={realPrice}
                  //
                  // InputHTMLAttributes由来
                  //
                  name="price"
                  required={true}
                />
              </Box>

              {/* 値段を表示しない */}
              <Box display="flex" alignItems="center">
                <Switch
                  checked={values.is_price_hidden}
                  color="secondary"
                  edge="start"
                  name="is_price_hidden"
                  onChange={handleChange}
                  value={values.is_price_hidden}
                />
                <FieldTitle
                  title={'値段を表示しない'}
                  tooltipText={
                    '値段を表示したくない商品の場合は、こちらにチェックマークを付けてください'
                  }
                />
              </Box>
            </div>

            {/* ジャンル */}
            <div>
              <FieldTitle
                title={t('Genre')}
                tooltipText={t('ui_products:Hint of genre')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.genre && errors.genre)}
                  fullWidth={true}
                  name="genre"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select={true}
                  SelectProps={{ native: true }}
                  value={values.genre}
                  variant="outlined"
                >
                  {genreOptions.map((genreOption) => (
                    <option key={genreOption.value} value={genreOption.value}>
                      {genreOption.label}
                    </option>
                  ))}
                </TextField>
              </Box>
            </div>

            {/* 説明 */}
            <div>
              <FieldTitle
                title={t('Additional description')}
                tooltipText={t('ui_products:Hint for additional description')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth={true}
                  multiline={true}
                  name="description"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    // multilineにすることで入力をしやすくしつつ、改行は入力させたくないという要件を満たすため、手動で処理している
                    setFieldValue(
                      'description',
                      removeLineBreaks(e.target.value),
                    )
                  }}
                  value={values.description}
                  variant="outlined"
                />
              </Box>
            </div>

            {/* 詳細説明 */}
            <div>
              <FieldTitle
                title={t('Detailed description')}
                tooltipText={t('ui_products:Hint for detailed description')}
              />
              <Box mt={1}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth={true}
                  multiline={true}
                  name="description_detail"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    // multilineにすることで入力をしやすくしつつ、改行は入力させたくないという要件を満たすため、手動で処理している
                    setFieldValue(
                      'description_detail',
                      removeLineBreaks(e.target.value),
                    )
                  }}
                  value={values.description_detail}
                  variant="outlined"
                />
              </Box>
            </div>

            {/* 非表示 */}
            <div
              css={css`
                position: relative;
              `}
            >
              <Box display="flex" alignItems="center">
                <Switch
                  checked={values.is_hidden}
                  color="secondary"
                  edge="start"
                  name="is_hidden"
                  onChange={handleChange}
                  value={values.is_hidden}
                />
                <FieldTitle
                  title={t('Hidden')}
                  tooltipText={t('ui_products:Hint for hiding')}
                />
              </Box>

              {/* 売り切れ */}
              <Box display="flex" alignItems="center">
                <Switch
                  checked={values.sold_out}
                  color="secondary"
                  disabled={values.is_hidden}
                  edge="start"
                  name="sold_out"
                  onChange={handleChange}
                  value={values.sold_out}
                />
                <FieldTitle
                  title={t('Sold out')}
                  tooltipText={t('ui_products:Hint for selling out')}
                />
              </Box>
            </div>

            {/* 写真 */}
            <div css={styles.photoGridItem}>
              <FieldTitle title={t('Photo')} />
              <Box mt={1}>
                <div css={styles.imageContainer}>
                  {values.imgix_filename ? image : imageDropZone}
                </div>
              </Box>
            </div>
          </div>

          <Divider css={styles.divider} />
          <FormikErrors touched={touched} errors={errors} />
          <Box mt={2} display="flex" justifyContent="flex-end">
            {!isNew && (
              <Button
                onClick={() =>
                  existingMenuitem &&
                  duplicateMenuitem(existingMenuitem.id, existingMenuitem.name)
                }
                disabled={isDuplicating}
                variant="outlined"
                color="primary"
                size="medium"
                sx={{ mr: 2 }}
              >
                複製する
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={isUpdating}
            >
              {isNew ? t('Add') : t('Update')}
            </Button>
          </Box>
        </CardContent>
      </Card>

      {!isNew && (
        <Box mt={2}>
          <Button
            onClick={() =>
              existingMenuitem &&
              deleteMenuitem(existingMenuitem.id, existingMenuitem.name)
            }
            disabled={isDeleting}
            aria-label="delete"
            size="small"
            tabIndex={-1}
          >
            {t('Delete')}
          </Button>
        </Box>
      )}
    </form>
  )
}

const styles = {
  grid: css`
      align-items: flex-start;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      @media (min-width: 960px) {
        grid-template-columns: 1fr 1fr;
      }
    `,
  // react-number-formatとmaterial-uiのTextFieldを組み合わせると、
  // モバイル端末からの入力時に文字がダブって重複される問題があり、解決できなかった。
  // このため、react-number-formatのスタイルをmui風に上書きすることにした。
  priceInput: css`
      border-radius: 4px;
      border: #aaa 1px solid;
      font-size: 16px;
      font-family: Roboto, Helvetica, Arial, "Noto Sans JP", sans-serif;
      height: 56px;
      padding: 0 12px;
      width: 100%;

      :hover {
          border: rgb(38, 50, 56) 1px solid;
      }

      :focus {
          border: #EB6442 2px solid;
          outline: none;
      }
  `,
  priceInputOnError: css`
      border: #c23f38 1px solid;
  `,
  priceInputDisabled: css`
      background-color: #f5f5f5;
      color: #9e9e9e;
      cursor: not-allowed;
  `,
  // 価格セルはスイッチを含んでおり、下側に何も表示しない方がみためが良いので
  priceCell: css`
    @media (min-width: 960px) {
        grid-row: span 2;
    }
  `,
  photoGridItem: css`
      @media (min-width: 960px) {
        grid-column: 2 / 3;
        grid-row: 3 / 6;
      }
    `,
  imageContainer: css`
      max-width: 260px;
    `,
  image: css`
      background: #fafafa;
      position: relative;
    `,
  imageDeleteButton: css`
      background: rgba(84, 110, 122, 0.1);
      color: white;
      margin: 0.5rem;
      position: absolute;
      right: 0;
      top: 0;
      :hover {
        background: rgba(84, 110, 122, 0.2);
      }
    `,
  imageDropzone: css`
      align-items: center;
      background-color: #fafafa;
      border-radius: 2px;
      border-style: dashed;
      border-width: 2px;
      color: #bdbdbd;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      outline: none;
      padding: 20px;
      text-align: center;
      transition: border 0.24s ease-in-out;
    `,
  divider: css`
      margin-top: 1rem;
    `,
}
